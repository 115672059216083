import React from 'react';
import SharedStyles from '../../sharedstyles';
import { withRouter } from 'react-router-dom';
import utils from '../../utils/utils';

class component extends React.Component {

    render (){
        return (
            <div style={styles.container} className="hover-on-white" onClick={this.onClick.bind(this)}>
                {this.getSelectedIndicator()}
                <div style={styles.title}>{this.props.data.path}</div>
                <div style={styles.metaDataContainer}>
                {this.getFieldsSummary()}
                </div>
            </div>
        )
    }

    getSelectedIndicator(){
        if (this.props.selectedData && this.props.selectedData.path == this.props.data.path){
            return (<div style={styles.selectedIndicator}></div>)
        }
    }

    getFieldsSummary (){

        var style = {
            fontSize: 12,
            backgroundColor: 'grey',
            color: 'white',
            height: 18,
            borderRadius: 9,
            paddingLeft: 10,
            paddingRight: 10,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: 4,
        }

        var filledCount = 0;
        var wordCount = 0;

        for (var i = 0; i < this.props.fields.length; i++){
            var fieldId = this.props.fields[i].id;

            if (fieldId in this.props.data){
                filledCount += 1;

                wordCount += utils.countHtmlWords(this.props.data[fieldId]);
            }
        }

        var completeness = filledCount / this.props.fields.length;

        var completenesStyle = JSON.parse(JSON.stringify(style));
        if (completeness < 0.4){
            completenesStyle.backgroundColor = SharedStyles.red;
        }
        else if (completeness < 0.7){
            completenesStyle.backgroundColor = SharedStyles.yellow;
        }
        else {
            completenesStyle.backgroundColor = SharedStyles.green;
        }

        var wordCountStyle = JSON.parse(JSON.stringify(style));
        if (wordCount < 700){
            wordCountStyle.backgroundColor = SharedStyles.red;
        }
        else if (wordCount < 1200){
            wordCountStyle.backgroundColor = SharedStyles.yellow;
        }
        else {
            wordCountStyle.backgroundColor = SharedStyles.green;
        }

        return (
            <>
                <span style={completenesStyle} title={"Completeness of the page. Calculated by filled questions / all questions"}>Q: {filledCount}/{this.props.fields.length}</span>
                <span style={wordCountStyle} title={"Word count only count the content, exclude html tags."}>WC: {wordCount}</span>
            </>
            )
    }

    onClick(){
        if (this.props.onClick){
            this.props.onClick(this.props.data);
        }
    }
}

var styles = {
    container: {
        position: 'relative',
        padding: `10px ${SharedStyles.horizontalSpacing}px`,
        borderBottom: `solid 1px ${SharedStyles.borderColor}`,
        cursor: 'pointer',
    },
    title: {
        fontWeight: 600,
        marginBottom: 5,
    },
    metaDataContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    selectedIndicator: {
        position: 'absolute',
        width: 6,
        height: '100%',
        backgroundColor: SharedStyles.blue,
        top: 0,
        left: 0,
    }
}

export default component;