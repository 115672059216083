import React from 'react';
import SharedStyles from '../../sharedstyles';
import utils from '../../utils/utils';

class component extends React.Component {
    render (){
        return (
            <div style={styles.container} className="hover-on-white"onClick={this.onClick.bind(this)}>
                {this.getSelectedIndicator()}
                <div style={styles.title}>{this.props.data.displayName}</div>
                <div style={styles.metaDataContainer}>
                    {this.getFieldsSummary()}
                </div>
            </div>
        )
    }

    getSelectedIndicator(){
        if (this.props.selectedField && this.props.selectedField.id === this.props.data.id){
            return (<div style={styles.selectedIndicator}></div>)
        }
    }

    getFieldsSummary(){
        var style = {
            fontSize: 12,
            backgroundColor: 'grey',
            color: 'white',
            height: 18,
            borderRadius: 9,
            paddingLeft: 10,
            paddingRight: 10,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: 4,
        }

        var wcStyle = JSON.parse(JSON.stringify(style));
        wcStyle.backgroundColor = '#FAFAFA';
        wcStyle.color = 'black';
        wcStyle.border = `solid 1px ${SharedStyles.borderColor}`

        var emptyStyle = JSON.parse(JSON.stringify(style));
        emptyStyle.backgroundColor = SharedStyles.red;

        if (this.props.data.id in this.props.selectedMagicCarpet){

            var wordCount = utils.countHtmlWords(this.props.selectedMagicCarpet[this.props.data.id]);
            return (
                <div style={wcStyle}>WC: {wordCount}</div>
            )
        }
        else {
            return (
                <div style={emptyStyle}>EMPTY</div>
            )
        }
    }

    onClick(){
        if (this.props.onClick){
            this.props.onClick(this.props.data);
        }
    }
}

var styles = {
    container: {
        position: 'relative',
        padding: `10px ${SharedStyles.horizontalSpacing}px`,
        borderBottom: `solid 1px ${SharedStyles.borderColor}`,
        cursor: 'pointer',
    },
    title: {
        fontWeight: 600,
        marginBottom: 5,
    },
    metaDataContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    selectedIndicator: {
        position: 'absolute',
        width: 6,
        height: '100%',
        backgroundColor: SharedStyles.blue,
        top: 0,
        left: 0,
    }
}

export default component;