import React from 'react';
import Editor from 'react-simple-code-editor';
import TemplateSelector from './templateselector';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/themes/prism.css';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-markup';
import RichTextEditor from 'react-rte';
import SharedStyles from '../../sharedstyles';
import utils from '../../utils/utils';
import PageModeSelector from './pagemodeselector';

class component extends React.Component {
    constructor(props) {
        super(props);
        var initialValue = this.props.mcData[this.props.fieldData.id] ? this.props.mcData[this.props.fieldData.id] : "";
        this.state = {
            mcData: this.props.mcData,
            fieldData: this.props.fieldData,
            code: initialValue,
            editorValue: RichTextEditor.createValueFromString(initialValue, 'html'),
            currentMode: 'editor',
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (
            props.mcData !== state.mcData ||
            props.fieldData !== state.fieldData
        ) {
            var newValue = props.mcData[props.fieldData.id] ? props.mcData[props.fieldData.id] : "";
            return {
                mcData: props.mcData,
                fieldData: props.fieldData,
                code: newValue,
                editorValue: RichTextEditor.createValueFromString(newValue, 'html'),
            };
        }
        return null;
    }

    render() {
        return (
            <div style={styles.container}>
                <div style={styles.titleSection}>
                    <div style={styles.title}>{this.state.mcData.path} <div style={styles.editPathButton} onClick={this.onEditPathButtonClick.bind(this)}>Edit</div></div>
                    <div style={styles.topic}>{this.state.fieldData.displayName}</div>
                </div>
                <div style={styles.statusSection}>
                    <div>Word count: {utils.countHtmlWords(this.state.code)}</div>
                    <div style={styles.statusGroup}>
                        {/* <TemplateSelector onChange={this.onSelectTemplate.bind(this)}/> */}
                        <PageModeSelector onClick={this.onModeChange.bind(this)} selected={this.state.currentMode} />
                        <div style={styles.saveButton} onClick={this.onSaveClick.bind(this)}>Save</div>
                    </div>
                </div>
                <div style={styles.contentContainer}>
                    {this.renderContent()}
                </div>
            </div>
        )
    }

    renderContent() {
        if (this.state.currentMode === "editor") {
            return (
                <RichTextEditor
                    value={this.state.editorValue}
                    onChange={value => this.onValueChange(value, true)}
                    style={styles.richEditor}
                    className="rich-editor-container"
                    editorClassName="rich-editor"
                    editorContainerClassName="rich-editor-container"
                />
            )
        }
        else if (this.state.currentMode === "preview") {
            return (
                <div style={styles.previewSection} dangerouslySetInnerHTML={{ __html: this.state.code }}></div>
            )
        }
        else {
            return (
                <Editor
                    value={this.state.code}
                    onValueChange={code => this.onValueChange(code)}
                    highlight={code => highlight(code, languages.markup)}
                    padding={SharedStyles.horizontalSpacing}
                    tabSize={2}
                    insertSpaces={true}
                    style={styles.editor}
                />
            )
        }
    }

    onEditPathButtonClick() {
        var result = window.prompt("New MC slug", "");

        if (!result || result.trim().length == 0) {
            return;
        }

        if (this.props.onSavePathChange) {
            this.props.onSavePathChange(result);
        }
    }

    onModeChange(id) {
        this.setState({
            currentMode: id
        })
    }

    onSelectTemplate(template) {
        console.log(template);

        this.setState({
            code: this.state.code + template,
        })
    }

    onValueChange(value, isFromEditor = false) {
        if (isFromEditor) {
            this.setState({
                code: value.toString('html'),
                editorValue: value
            });
        }
        else {
            this.setState({
                code: value,
                editorValue: RichTextEditor.createValueFromString(value, 'html')
            });
        }

        // prevent user from navigating away when there's change.
        if (this.props.onUnsavedChange) {
            this.props.onUnsavedChange();
        }
    }

    onSaveClick() {
        if (this.props.onSave) {
            this.props.onSave(this.state.code);
        }
    }
}

var styles = {
    container: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    contentContainer: {
        overflowY: 'scroll',
    },
    titleSection: {
        borderBottom: `solid 1px ${SharedStyles.borderColor}`,
        padding: `16px ${SharedStyles.horizontalSpacing}px`,
    },
    statusSection: {
        borderBottom: `solid 1px ${SharedStyles.borderColor}`,
        padding: `6px ${SharedStyles.horizontalSpacing}px`,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    statusGroup: {
        display: 'flex',
        flexDirection: 'row',
    },
    bottomSection: {
        borderTop: `solid 1px ${SharedStyles.borderColor}`,
        padding: `10px ${SharedStyles.horizontalSpacing}px`,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    title: {
        fontSize: 30,
        fontWeight: 700,
        marginBottom: 8,
    },
    topic: {
        fontSize: 16,
        color: SharedStyles.blue,
        fontWeight: 600,
    },
    editor: {
        flex: 1,
        fontFamily: '"Fira code", "Fira Mono", monospace',
        fontSize: 14,
        margin: 2,
    },
    richEditor: {
        flex: 1,
    },
    previewSection: {
        flex: 1,
        padding: `16px ${SharedStyles.horizontalSpacing}px`,
    },
    saveButton: {
        backgroundColor: SharedStyles.green,
        color: 'white',
        borderRadius: 6,
        padding: '4px 8px',
        cursor: 'pointer',
        marginLeft: 6,
    },
    editPathButton: {
        display: 'inline-block',
        fontSize: 12,
        color: 'red',
        cursor: 'pointer',
    }
}

export default component;