import React from 'react';
import csv from 'csvtojson'
import { withRouter } from 'react-router';
import KeywordAnalyzer from '@seo/benchmark-research/keyword-analyzer'

class component extends React.Component {
    constructor() {
        super();

        this.state = {
            selectedFile: null,
            selectedFileJsonArray: null,
            analyzeResult: null,
            displayTopN: 100,
            sortBy: "top10Count",
            maxPos: 20,
            order: "descend",
            minTotalCount: -1,
            showSideTable: false,
            sideTableQueryKeyword: null,
            sideTableQueryMaxPosition: -1,
            sideTableResult: null,
        }
    }
    render() {
        return (
            <div style={styles.container}>
                <div style={styles.headerContainer}>
                    <b>Keyword benchmark</b>
                    <div style={styles.controlsContainer}>
                        <input type="file" onChange={this.onFileChange.bind(this)} />
                        <button onClick={this.processFile.bind(this)}>Process</button>
                    </div>
                    {this.renderFilters()}
                </div>
                <div style={styles.resultsContainer}>
                    {this.renderResults()}
                    {this.renderSideTable()}
                </div>
            </div>
        );
    }

    renderFilters() {
        if (!this.state.analyzeResult) {
            return null;
        }

        return (
            <div style={styles.controlsContainer}>
                <div style={styles.filterContainer}>
                    <abbr title="Display top N results. If the value is 50, it will display 50 top results.">Show top N:</abbr>
                    <input type="text" onChange={this.onTopNChange.bind(this)} value={this.state.displayTopN} />
                </div>
                <div style={styles.filterContainer}>
                    <abbr title="Sort the table by field.">Sort by:</abbr>
                    <select onChange={this.onSortByChange.bind(this)} value={this.state.sortBy}>
                        <option value="median">Median</option>
                        <option value="totalCount">Total count</option>
                        <option value="top1Count">Top 1 count</option>
                        <option value="top5Count">Top 5 count</option>
                        <option value="top10Count">Top 10 count</option>
                    </select>
                </div>
                <div style={styles.filterContainer}>
                    <abbr
                        title="Default is 20, means we only analyze keywords that are ranked abve 20. This is useful when you set a smaller number, such as 10, to explore all keywords ranked at 10 or higher.">
                        Max position:
                    </abbr>
                    <input type="text" onChange={this.onMaxPositionChange.bind(this)} value={this.state.maxPos} />
                </div>
                <div style={styles.filterContainer}>
                    <abbr
                        title="Default is -1, means we do not limit the total count of occurance of a keyword. You can set it to a higher value, such as 20, to explore keywords that are more scalable.">
                        Min total count:
                    </abbr>
                    <input type="text" onChange={this.onMinTotalCountChange.bind(this)} value={this.state.minTotalCount} />
                </div>
                <div style={styles.filterContainer}>
                    Order:
                    <select onChange={this.onOrderChange.bind(this)} value={this.state.order}>
                        <option value="ascend">Ascend</option>
                        <option value="descend">Descend</option>
                    </select>
                </div>
            </div>
        )
    }

    renderSideTable() {
        if (this.state.showSideTable && this.state.analyzeResult) {
            return (
                <>
                    <div style={{ cursor: 'pointer' }} onClick={this.closeSideTable.bind(this)}>
                        {">>"}
                    </div>
                    {this.renderSideResults()}
                </>
            );
        }
        else {
            return <></>
        }
    }

    prepareSideResult() {
        if (!this.state.analyzeResult) {
            return null;
        }

        console.log(this.state.sideTableQueryKeyword);

        var filteredAnalyzeResult = this.state.selectedFileJsonArray.filter(function (kw) {
            var keywordMatch = kw.Keyword.indexOf(this.state.sideTableQueryKeyword) >= 0;
            var position = Number(kw["Current position"]);

            // filter only the keywords that has string match plus position match if specified
            if (keywordMatch &&
                (this.state.sideTableQueryMaxPosition < 0 ||
                    (kw["Current position"].length > 0 &&
                        this.state.sideTableQueryMaxPosition >= 0 &&
                        position <= this.state.sideTableQueryMaxPosition))) {
                return true;
            }
            return false;
        }.bind(this))

        this.setState({
            sideTableResult: filteredAnalyzeResult,
        })
    }

    renderSideResults() {
        if (!this.state.sideTableResult) {
            return null;
        }

        var results = [];
        var sideTableResult = JSON.parse(JSON.stringify(this.state.sideTableResult));
        for (var i = 0; i < sideTableResult.length; i++) {
            var resultData = sideTableResult[i];
            results.push(
                <tr style={styles.cell}>
                    <td style={styles.cell}>{i + 1}</td>
                    <td style={styles.cell}>{resultData["Keyword"]}</td>
                    <td style={styles.cell}>{resultData["Volume"]}</td>
                    <td style={styles.cell}>{resultData["Current position"]}</td>
                    <td style={styles.cell}>{resultData["KD"]}</td>
                    <td style={styles.cell}>
                        <a href={resultData["Current URL"]} target="_blank">Link</a>
                    </td>
                    <td style={styles.cell}>
                        {
                            resultData["metaMatch"]
                                ? (<div>{resultData["metaMatch"]}</div>)
                                : (<div onClick={this.checkMetaMatch.bind(this, resultData, sideTableResult)}>Check</div>)
                        }
                    </td>
                </tr>
            )
        }

        return (
            <div style={styles.tableContainer}>
                <table style={styles.table}>
                    <tr>
                        <th style={styles.header}>#</th>
                        <th style={styles.header}>Keyword</th>
                        <th style={styles.header}>Volume</th>
                        <th style={styles.header}>Position</th>
                        <th style={styles.header}>KD</th>
                        <th style={styles.header}>URL</th>
                        <th style={styles.header}>Meta match</th>
                    </tr>
                    {results}
                </table>
            </div>
        );
    }

    renderResults() {
        if (!this.state.analyzeResult) {
            return null;
        }

        var results = [];

        for (var i = 0; i < this.state.analyzeResult.length; i++) {
            var resultData = this.state.analyzeResult[i];
            results.push(
                <tr style={styles.cell}>
                    <td style={styles.cell}>{i + 1}</td>
                    <td style={styles.cell}>{resultData.keyword}</td>
                    <td style={styles.cell}>
                        <div style={styles.interactiveContent} onClick={this.showSideTable.bind(this, resultData.keyword, -1)}>
                            {resultData.totalCount}
                        </div>
                    </td>
                    <td style={styles.cell}>
                        <div style={styles.interactiveContent} onClick={this.showSideTable.bind(this, resultData.keyword, 1)}>
                            {resultData.top1Count}
                        </div>
                    </td>
                    <td style={styles.cell}>
                        <div style={styles.interactiveContent} onClick={this.showSideTable.bind(this, resultData.keyword, 5)}>
                            {resultData.top5Count}
                        </div>
                    </td>
                    <td style={styles.cell}>
                        <div style={styles.interactiveContent} onClick={this.showSideTable.bind(this, resultData.keyword, 10)}>
                            {resultData.top10Count}
                        </div>
                    </td>
                    <td style={styles.cell}>{resultData.median}</td>
                </tr>
            )
        }

        return (
            <div style={styles.tableContainer}>
                <table style={styles.table}>
                    <tr>
                        <th style={styles.header}>#</th>
                        <th style={styles.header}>Keyword</th>
                        <th style={this.getHighlightedHeader("totalCount")}>
                            <abbr title="Total number of this keyword appears in the report">Total count</abbr>
                        </th>
                        <th style={this.getHighlightedHeader("top1Count")}>
                            <abbr title="Total number of this keyword that ranked top 1 in the report">Count of Top 1</abbr>
                        </th>
                        <th style={this.getHighlightedHeader("top5Count")}>
                            <abbr title="Total number of this keyword that ranked top 5 in the report">Count in Top 5</abbr>
                        </th>
                        <th style={this.getHighlightedHeader("top10Count")}>
                            <abbr title="Total number of this keyword that ranked top 10 in the report">Count in Top 10</abbr>
                        </th>
                        <th style={this.getHighlightedHeader("median")}>
                            <abbr title="Median of positions of this keyword">Median position</abbr>
                        </th>
                    </tr>
                    {results}
                </table>
            </div>
        );
    }

    checkMetaMatch(keywordData, filteredList) {
        keywordData["metaMatch"] = "Title [0/3] Description [1/3]";
        this.setState({
            sideTableResult: filteredList,
        })
    }

    closeSideTable() {
        this.setState({
            showSideTable: false,
            sideTableQueryKeyword: null,
            sideTableQueryMaxPosition: -1,
            sideTableResult: null,
        })
    }

    showSideTable(keyword, maxPosition = -1) {
        this.setState({
            showSideTable: true,
            sideTableQueryKeyword: keyword,
            sideTableQueryMaxPosition: maxPosition,
        }, function () {
            this.prepareSideResult();
        }.bind(this))
    }

    onFileChange(event) {
        this.setState(
            {
                selectedFile: event.target.files[0]
            }
        );
    }

    onTopNChange(event) {
        this.setState({
            displayTopN: Number(event.target.value),
        })
    }

    onMaxPositionChange(event) {
        this.setState({
            maxPos: Number(event.target.value),
        })
    }

    onMinTotalCountChange(event) {
        this.setState({
            minTotalCount: Number(event.target.value),
        })
    }

    onSortByChange(event) {
        this.setState({
            sortBy: event.target.value,
        });
    }

    onOrderChange(event) {
        this.setState({
            order: event.target.value,
        });
    }

    processFile() {
        if (!this.state.selectedFile) {
            alert("Choose a Ahref Organic Keywords 2.0 CSV file first.")
        }
        const reader = new FileReader()
        reader.onload = async function (e) {
            const csvText = (e.target.result)
            this.analyzeKeywords(csvText)
        }.bind(this);

        reader.readAsText(this.state.selectedFile);
    }

    analyzeKeywords(csvText) {
        csv({})
            .fromString(csvText)
            .then(function (jsonText) {
                console.log(jsonText);
                var result = KeywordAnalyzer(
                    jsonText,
                    null,
                    null,
                    this.state.maxPos,
                    this.state.sortBy,
                    this.state.minTotalCount,
                    this.state.order,
                    "space").slice(0, this.state.displayTopN);
                this.setState({
                    analyzeResult: result,
                    selectedFileJsonArray: jsonText,
                    // reset side table
                    showSideTable: false,
                    sideTableQueryKeyword: null,
                    sideTableQueryMaxPosition: -1,
                });
            }.bind(this));
    }

    getHighlightedHeader(matchSortByName) {
        if (this.state.sortBy === matchSortByName) {
            return styles.headerHighlighted;
        }

        return styles.header;
    }
}

var styles = {
    container: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column'
    },
    headerContainer: {
        height: '15vh',
    },
    resultsContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
    },
    tableContainer: {
        height: '85vh',
        overflow: "auto",
        flex: 1,
    },
    table: {
        width: '100%'
    },
    cell: {
        border: "1px solid #dddddd",
        textAlign: "center",
        padding: 8,
    },
    header: {
        position: 'sticky',
        top: 0,
        border: "1px solid #dddddd",
        textAlign: "center",
        padding: 8,
        backgroundColor: '#EEEEEE',
    },
    headerHighlighted: {
        position: 'sticky',
        top: 0,
        border: "1px solid #dddddd",
        textAlign: "center",
        padding: 8,
        backgroundColor: 'red',
    },
    filterContainer: {
        display: 'inline-block',
        backgroundColor: '#EFEFEF',
        marginRight: 10,
        padding: '5px 5px',
    },
    controlsContainer: {
        marginTop: 8,
        marginBottom: 8,
    },
    interactiveContent: {
        cursor: 'pointer',
        textDecoration: "underline",
        textDecorationStyle: "solid",
    }
}

export default component;