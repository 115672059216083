import React from 'react';
import app from '../firebase';
import firebase from 'firebase';
import { withRouter } from 'react-router';

class component extends React.Component {
    render (){
        return (
            <button onClick={this.onSignInClick.bind(this)}>sign in</button>
        )
    }

    onSignInClick(){
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(function(){
            return firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider());
        })
        .then(function(result) {
            // This gives you a Google Access Token. You can use it to access the Google API.
            var token = result.credential.accessToken;
            // The signed-in user info.
            var user = result.user;
            
            console.log("logined in");
            console.log(result);
            
            this.props.history.push('/');
          }.bind(this)).catch(function(error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;

            console.log(error);
            // ...
          });
    }
}

export default component;