var fs = require('fs');
const csv = require('csvtojson')

var utils = {
    readFromFile: function (path) {
        try {
            const data = fs.readFileSync(path, 'utf8')
            return data;
        } catch (err) {
            throw new Error(`Failed reading from file: ${path}`);
        }
    },
    //var csv is the CSV file with headers
    csvToObject: async function (csvPath) {
        return await csv().fromFile(csvPath);
    },

    isNumeric: function (str) {
        if (typeof str != "string") return false // we only process strings!  
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(Number(str)) // ...and ensure strings of whitespace fail
    },

    generateNGram: function (kw, n, wordBreakStyle = "space") {
        kw = kw.toLowerCase();
        var results = [];
        var mapping = [];
        var kw_arr = [];
        if (wordBreakStyle == "space") {
            kw_arr = kw.replace(/ /g, ",").split(",");
        }
        else if (wordBreakStyle == "character") {
            kw_arr = kw.replace(/ /g, "").split("");
        }

        for (var g = 1; g <= n; g++) {
            for (var i = 0; i + g - 1 < kw_arr.length; i++) {
                var s = "";
                for (var ss = i; ss <= i + g - 1; ss++) {
                    if (wordBreakStyle == "space") {
                        s += kw_arr[ss] + " ";
                    }
                    else if (wordBreakStyle == "character") {
                        s += kw_arr[ss]
                    }
                }

                results.push(s.trim());
            }
        }

        console.log(results);
        return results;
    },

    median: function (values) {
        values.sort((a, b) => b - a);
        return values[Math.floor(values.length / 2)];
    }
}

module.exports = utils;