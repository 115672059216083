import * as firebase from 'firebase/app';
import 'firebase/auth';

const app = firebase.initializeApp({
    apiKey: "AIzaSyBAgzWMZe0M-nNF-vXscfzxhDFrvgIBu2U",
    authDomain: 'pageone-lab-tools.firebaseapp.com',
    databaseURL: 'https://pageone-lab-tools.firebaseio.com',
    projectId: 'pageone-lab-tools',
    storageBucket: 'pageone-lab-tools.appspot.com',
    messagingSenderId: '665506097481'
});

export default app;