import React from 'react';
import SharedStyles from '../../sharedstyles';

const Templates = {
    faq: {
        displayName: "Ordered List of POIs",
        template: 
`
<ul>
    <li class="list-item" itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
        <meta itemprop="description" content="" />
        <meta itemprop="position" content="0" />
        <meta itemprop="url" content="" />
        <div class="list-item-content">
            <header><h4>item name goes here</h4></header>
            <summary><p>item detail goes here</p></summary>
        </div>
    </li>
</ul>
`,
    },
    orderedList: {
        displayName: "Ordered List",
        template: 
`
<!-- Ordered List -->
<list>
    <item></item>
</list>
`,
    }
}

class component extends React.Component {
    render(){
        return (
            <select onChange={this.onChange.bind(this)}>
                {this.getOptions()}
            </select>
        )
    }

    getOptions(){
        var options = [];
        var keys = Object.keys(Templates);

        options.push(
            <option selected disabled>Add a template</option>
        )

        for (var i = 0; i < keys.length; i++){
            var key = keys[i];
            var name = Templates[key].displayName ? Templates[key].displayName : key;
            options.push(<option value={key} id={key}>{name}</option>);
        }

        return options; 
    }

    onChange(e){
        var value = e.target.value;
        if (this.props.onChange && Templates[value]){
            this.props.onChange(Templates[value].template);
        }
    }
}

var styles = {

}

export default component;