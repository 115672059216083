import React from 'react';
import SharedStyles from '../../sharedstyles';

class component extends React.Component {
    render() {
        return (
            <div style={styles.container} className="hover-on-white" onClick={this.onClick.bind(this)}>
                {this.getSelectedIndicator()}
                <div style={styles.title}>{this.props.displayName}</div>
            </div>
        )
    }

    getSelectedIndicator() {
        if (this.props.selectedField && this.props.selectedField === this.props.id) {
            return (<div style={styles.selectedIndicator}></div>)
        }
    }

    onClick() {
        if (this.props.onClick) {
            this.props.onClick(this.props.id);
        }
    }
}

var styles = {
    container: {
        position: 'relative',
        padding: `10px ${SharedStyles.horizontalSpacing}px`,
        borderBottom: `solid 1px ${SharedStyles.borderColor}`,
        cursor: 'pointer',
    },
    title: {
        fontWeight: 600,
        marginBottom: 5,
    },
    metaDataContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    selectedIndicator: {
        position: 'absolute',
        width: 6,
        height: '100%',
        backgroundColor: SharedStyles.blue,
        top: 0,
        left: 0,
    }
}

export default component;