import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Home from './pages/home';
import Login from './pages/login';
import Logout from './pages/logout';
import ContentEditor from './pages/contenteditor';
import KeywordBenchmark from './pages/keywordbenchmark';
import BlogCMS from './pages/blogcms';
import SemModeler from './pages/semmodeler';
import { AuthProvider } from './auth';
import PrivateRoute from './privateroute';

class App extends Component {
  render() {
    return (
      <AuthProvider>
        <Router>
          <div style={styles.container}>
            <Route exact path="/login" component={Login} />
            <PrivateRoute exact path="/" component={Home} />
            <PrivateRoute exact path="/logout" component={Logout} />
            <PrivateRoute exact path="/content-editor" component={ContentEditor} />
            <PrivateRoute exact path="/keyword-benchmark" component={KeywordBenchmark} />
            <PrivateRoute exact path="/blog-cms" component={BlogCMS} />
            <PrivateRoute exact path="/sem-modeler" component={SemModeler} />
          </div>
        </Router>
      </AuthProvider>
    );
  }
}

var styles = {
  container: {
    height: '100%',
  }
}

export default App;