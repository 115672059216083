var grid = 16;
var borderColor = "#D8D8D8";

export default {
    horizontalSpacing: grid,
    borderColor: borderColor,

    red: '#d32f2f',
    yellow: '#EF9A07',
    green: '#00AB70',
    blue: '#4957A9',
}