import React from 'react';
import { Form, Input, Row, Button, Divider, Select, Image, Switch } from 'antd';

class component extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            numberOfMonth: 12,
            paybackMonth: 3,
            initialCashInBank: 30000,
            monthlyOperationalCost: 4000,
            seoInitialMonthlyRevenue: 20000,
            semROAS: 1.5,
            semSpendingPercentage: 0.8,
            seoMonthlyGrowthRate: 1.1,
            model: null,
        }
    }

    componentDidMount() {
    }

    render() {
        return (
            <div style={styles.container}>
                SEM Modeler
                <Form
                    layout="vertical"
                    size="large"
                    requiredMark="optional"
                >
                    <Form.Item
                        required
                        label="Number of months to model"
                        tooltip="How many month this model should model.">
                        <Input
                            allowClear
                            type="number"
                            placeholder="Number of months to model (i.e. 12)"
                            value={this.state.numberOfMonth}
                            onChange={(event) => this.setState({ numberOfMonth: Number(event.target.value) })} />
                    </Form.Item>
                    <Form.Item
                        required
                        label="Payback month"
                        tooltip="How many month between booking date to receiving payment.">
                        <Input
                            allowClear
                            type="number"
                            placeholder="Payback month"
                            value={this.state.paybackMonth}
                            onChange={(event) => this.setState({ paybackMonth: Number(event.target.value) })} />
                    </Form.Item>
                    <Form.Item
                        required
                        label="Monthly operational cost"
                        tooltip="A total cost of things like salary, software subscription, server, etc.">
                        <Input
                            allowClear
                            type="number"
                            placeholder="Monthly operational cost"
                            value={this.state.monthlyOperationalCost}
                            onChange={(event) => this.setState({ monthlyOperationalCost: Number(event.target.value) })} />
                    </Form.Item>
                    <Form.Item
                        required
                        label="Initial cash in bank"
                        tooltip="Initial cash in bank for both seo and sem model.">
                        <Input
                            allowClear
                            type="number"
                            placeholder="Initial cash in bank."
                            value={this.state.initialCashInBank}
                            onChange={(event) => this.setState({ initialCashInBank: Number(event.target.value) })} />
                    </Form.Item>
                    <Form.Item
                        required
                        label="SEO initial monthly revenue"
                        tooltip="Seo initial monthly revenue.">
                        <Input
                            allowClear
                            type="number"
                            placeholder="SEO initial monthly revenue"
                            value={this.state.seoInitialMonthlyRevenue}
                            onChange={(event) => this.setState({ seoInitialMonthlyRevenue: Number(event.target.value) })} />
                    </Form.Item>
                    <Form.Item
                        required
                        label="SEO Monthly growth rate"
                        tooltip="SEO Monthly growth rate.">
                        <Input
                            allowClear
                            type="number"
                            placeholder="SEO Monthly growth rate"
                            value={this.state.seoMonthlyGrowthRate}
                            onChange={(event) => this.setState({ seoMonthlyGrowthRate: Number(event.target.value) })} />
                    </Form.Item>
                    <Form.Item
                        required
                        label="SEM ROAS"
                        tooltip="SEM ROAS.">
                        <Input
                            allowClear
                            type="number"
                            placeholder="SEM ROAS"
                            value={this.state.semROAS}
                            onChange={(event) => this.setState({ semROAS: Number(event.target.value) })} />
                    </Form.Item>
                    <Form.Item
                        required
                        label="SEM Spending percentage"
                        tooltip="SEM Spending / cash in bank.">
                        <Input
                            allowClear
                            type="number"
                            placeholder="SEM Spending percentage"
                            value={this.state.semSpendingPercentage}
                            onChange={(event) => this.setState({ semSpendingPercentage: Number(event.target.value) })} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={this.runModel.bind(this)}>Run model</Button>
                    </Form.Item>
                </Form>
                <Divider>Results</Divider>
                {this.showModel()}
            </div>
        );
    }

    showModel() {
        if (!this.state.model) {
            return null;
        }

        var dataList = [];

        for (var i = 0; i < this.state.model.length; i++) {
            var data = this.state.model[i];
            dataList.push(
                <tr>
                    <td>{i + 1} month</td>
                    <td>
                        <div>cashInBank: ${Math.round(data.cashInBankSeo)} (after deduct op. cost)</div>
                        <div>seoRevenue: ${Math.round(data.seoRevenue)}</div>
                        <div><b>projected12MonthProfit: ${Math.round(data.projected12MonthProfitSeoOnly)} (monthly seo revenue * 12)</b></div>
                    </td>
                    <td>
                        <div>cashInBankBeforeSEM: ${Math.round(data.cashInBankBeforeSem)} (after deduct op. cost)</div>
                        <div>cashInBankAfterSEM: ${Math.round(data.cashInBankAfterSem)}</div>
                        <div>semSpending: ${Math.round(data.semSpending)}</div>
                        <div>seoRevenue: ${Math.round(data.seoRevenue)}</div>
                        <div>semRevenue: ${Math.round(data.semRevenue)}</div>
                        <div>semProfit: ${Math.round(data.semProfit)} (sem revenue - ads spending)</div>
                        <div>semRevenueSourceMonth: month {data.semRevenueSource + 1}</div>
                        <div><b>projected12MonthProfit: ${Math.round(data.projected12MonthProfitSeoSem)} (monthly seo + sem profit * 12)</b></div>
                    </td>
                </tr>
            )
        }

        return (
            <table style={styles.table} border="1">
                <tr>
                    <th>Month</th>
                    <th>SEO only</th>
                    <th>SEO + SEM</th>
                </tr>
                {dataList}
            </table>
        )
    }

    runModel() {
        var projectedPaymentsQueue = []

        var cashInBankSeo = this.state.initialCashInBank;
        var cashInBankBeforeSem = this.state.initialCashInBank;
        var cashInBankAfterSem = this.state.initialCashInBank;

        for (var i = 0; i < this.state.numberOfMonth; i++) {
            var lookbackIndex = i - this.state.paybackMonth;

            // this month's income from different sources
            var seoRevenue = 0;
            var semRevenue = 0;
            var semSpending = 0;
            var semProfit = 0;

            // For later months, we add the revenue come from X months ago.
            if (lookbackIndex >= 0) {
                var prevPaybackMonth = projectedPaymentsQueue[lookbackIndex];
                seoRevenue = this.state.seoInitialMonthlyRevenue * Math.pow(this.state.seoMonthlyGrowthRate, i - 3);
                semRevenue = prevPaybackMonth.semSpending * this.state.semROAS;
                semProfit = semRevenue - prevPaybackMonth.semSpending;
            }
            // For first X month we default to initial seo income and no sem income.
            else {
                seoRevenue = this.state.seoInitialMonthlyRevenue;
                semRevenue = 0;
            }

            cashInBankSeo = cashInBankSeo + seoRevenue - this.state.monthlyOperationalCost;
            cashInBankBeforeSem = cashInBankAfterSem + seoRevenue + semRevenue - this.state.monthlyOperationalCost;
            semSpending = cashInBankBeforeSem * this.state.semSpendingPercentage;
            cashInBankAfterSem = cashInBankBeforeSem - semSpending;

            // queue this month's seo & sem
            projectedPaymentsQueue.push({
                month: i,
                cashInBankSeo: cashInBankSeo,
                cashInBankBeforeSem: cashInBankBeforeSem,
                cashInBankAfterSem: cashInBankAfterSem,
                semSpending: semSpending,
                seoRevenue: seoRevenue,
                semRevenue: semRevenue,
                semProfit: semProfit,
                semRevenueSource: lookbackIndex,
                projected12MonthProfitSeoOnly: (seoRevenue) * 12,
                projected12MonthProfitSeoSem: (seoRevenue + semProfit) * 12,
            });
        }

        this.setState({
            model: projectedPaymentsQueue
        })
    }
}

var styles = {
    container: {
        padding: 20,
        height: '100vh',
        overflowY: 'auto',
    },
    table: {
        width: '100%'
    }
}

export default component;