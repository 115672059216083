import React from 'react';
import SharedStyles from '../../sharedstyles';

class component extends React.Component {
    constructor (props){
        super(props);
    }

    render (){
        return (
            <div style={styles.container}>
                <div style={this.getStyle("editor")} onClick={e => this.onClick("editor")}>Rich editor</div>
                <div style={this.getStyle("html")} onClick={e => this.onClick("html")}>HTML editor</div>
                <div style={this.getStyle("preview")} onClick={e => this.onClick("preview")}>Preview</div>
            </div>
        )
    }

    getStyle(id){
        if (id == this.props.selected){
            return {
                color: 'white',
                backgroundColor: SharedStyles.blue,
                padding: '4px 8px',
                borderRadius: 3,
            }
        }
        else {
            return {
                color: 'black',
                padding: '4px 8px',
            }
        }
    }

    onClick(id){
        if (this.props.onClick){
            this.props.onClick(id);
        }
    }
}

var styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        border: `solid 1px ${SharedStyles.borderColor}`,
        backgroundColor: '#FAFAFA',
        borderRadius: 3,
        cursor: 'pointer',
    }
}

export default component;