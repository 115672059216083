var Utils = require('./utils');

// Params
// - queryDetailsFromAhrefs: a parsed JSON representation of Ahrefs Organic Keyword 2.0 csv report.
// - stopwords: [optiona] a array of words of which we should not count as single keyword. It usually contains
//              common words such as "in", "near", etc.
// - filterKeyword: if specified, will only process queries with such keyword
// - maxPos: if value > 0, will only process kw that ranked higher than the value.
// - sortBy: output sort by which field. Can be "totalCount", "top1Count", "top5Count", "top10Count", "median"
// - minTotalCount: if specified, keywords with totalcount less than this value will be dropped. I.e. Y
//                  you can do "Give me all keywords sort by median but each keyword should appear at least 20 times"
//                  so that you can filter out high ranked kw but only appeared once (i.e. location specific kw that doesn't scale)
// - order: ascend or descend
function analyzeAhrefKeywordReport(
    queryDetailsFromAhrefs = [],
    stopwords = new Set(),
    filterKeyword = null,
    maxPos = -1,
    sortBy = "totalCount",
    minTotalCount = -1,
    order = "descend",
    wordBreakStyle = "space",
    ngramCount = 3) {

    // filter higher ranked kw by max position if specified.
    if (maxPos > 0) {
        queryDetailsFromAhrefs = queryDetailsFromAhrefs.filter(function (entry) {
            pos = Number(entry["Current position"]) ? Number(entry["Current position"]) : 999;
            return pos <= maxPos;
        })
    }

    // filter by keyword if needed.
    if (filterKeyword) {
        queryDetailsFromAhrefs = queryDetailsFromAhrefs.filter(function (entry) {
            return entry.Keyword.toLowerCase().indexOf(filterKeyword.toLowerCase()) >= 0;
        })
    }

    var keywordDetails = {};

    // generate word cloud
    for (var i = 0; i < queryDetailsFromAhrefs.length; i++) {
        var queryObject = queryDetailsFromAhrefs[i];
        var query = queryObject.Keyword;
        var nGram = Utils.generateNGram(query, ngramCount, wordBreakStyle);

        nGram.forEach(function (kw) {

            // skip common words
            if (stopwords && stopwords.has(kw)) {
                return;
            }

            if (kw == "constructor") {
                kw = `[${kw}]`;
            }

            if (!keywordDetails[kw]) {
                keywordDetails[kw] = {
                    totalCount: 0,
                    countInTop1: 0,
                    countInTop5: 0,
                    countInTop10: 0,
                    pos: [],
                };
            }
            console.log("keyword is " + kw);
            console.log("keyword detail is " + JSON.stringify(keywordDetails[kw]));
            keywordDetails[kw].totalCount += 1;
            var position = Number(queryObject["Current position"]) ? Number(queryObject["Current position"]) : 999;
            keywordDetails[kw].pos.push(position);

            if (position <= 10) { keywordDetails[kw].countInTop10 += 1; }
            if (position <= 5) { keywordDetails[kw].countInTop5 += 1; }
            if (position == 1) { keywordDetails[kw].countInTop1 += 1; }
        });
    }

    // transform map to array so that we can sort
    var keywordsWithCountArray = [];
    Object.keys(keywordDetails).forEach(function (key) {
        keywordsWithCountArray.push(
            {
                keyword: key,
                totalCount: keywordDetails[key].totalCount,
                top1Count: keywordDetails[key].countInTop1,
                top5Count: keywordDetails[key].countInTop5,
                top10Count: keywordDetails[key].countInTop10,
                median: Utils.median(keywordDetails[key].pos),
            }
        )
    });

    // filter by total count and drop the small ones if needed
    if (minTotalCount > 0) {
        keywordsWithCountArray = keywordsWithCountArray.filter(function (a) {
            return a.totalCount > minTotalCount;
        })
    }

    keywordsWithCountArray = keywordsWithCountArray.sort(function (a, b) {
        if (order == "ascend") {
            return a[sortBy] - b[sortBy];
        }

        return b[sortBy] - a[sortBy];
    })

    return keywordsWithCountArray;
}

module.exports = analyzeAhrefKeywordReport;