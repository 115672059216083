import React from 'react';
import app from '../firebase';
import firebase from 'firebase';
import { withRouter } from 'react-router';

class component extends React.Component {
    render (){
        return (
            <button onClick={this.onLogoutClick.bind(this)}>log out</button>
        )
    }

    onLogoutClick(){
        firebase.auth().signOut().then(function(result) {    
            console.log('logged out')        
            this.props.history.push('/');
          }.bind(this)).catch(function(error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;

            console.log(error);
            // ...
          });
    }
}

export default component;