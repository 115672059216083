import React from 'react';
import app from '../firebase';
import MCListItem from '../components/contenteditor/mclistitem';
import MCFieldListItem from '../components/contenteditor/mcfieldlistitem';
import EditFieldHTML from '../components/contenteditor/editfieldhtml';
import SharedStyles from '../sharedstyles';
import utils from '../utils/utils';

class component extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            magicCarpets: [],
            magicCarpetFields: [],
            selectedMagicCarpet: null,
            selectedField: null,
            hasUnsavedChange: false,
            filterBy: null,
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    render() {
        return (
            <div style={styles.container}>
                {/* <div style={styles.navbar}>
                    Content Editor
                </div> */}
                <div style={styles.editorContainer}>
                    <div style={styles.column}>
                        <div style={styles.columnHeaderSection}>
                            <div style={styles.margetCarpetColumnTitle}>Magic Carpets ({this.state.magicCarpets.length})</div>
                        </div>
                        <div style={styles.columnHeaderSearchSection}>
                            <input
                                className="mcSearchInput"
                                style={styles.searchBox}
                                placeholder="Search"
                                value={this.state.filterBy}
                                onChange={e => this.setState({ filterBy: e.target.value })} />
                        </div>
                        <div style={styles.magicCarpetsContainer}>
                            {this.getMagicCarpetTitles()}
                        </div>
                        <div style={styles.columnFooterSection}>
                            <div style={styles.addMcButton} onClick={this.addMCButtonClicked.bind(this)}>+ Add new MC</div>
                        </div>
                        <div style={styles.columnFooterSection}>
                            <div style={styles.addMcButton} onClick={this.exportDataButtonClicked.bind(this)}>Export JSON Data</div>
                        </div>
                    </div>
                    {this.getFields()}
                    {this.getEditor()}
                </div>
            </div>
        );
    }

    getEditor() {
        if (!this.state.selectedMagicCarpet || !this.state.selectedField) {
            return null;
        }

        return (
            <div style={styles.contentEditorContainer}>
                <EditFieldHTML
                    mcData={this.state.selectedMagicCarpet}
                    fieldData={this.state.selectedField}
                    onSave={this.onContentSave.bind(this)}
                    onUnsavedChange={this.onUnSavedChangeStateChange.bind(this)}
                    onSavePathChange={this.onSavePathChange.bind(this)}
                />
            </div>)
    }

    getFields() {
        if (!this.state.magicCarpetFields || this.state.magicCarpetFields.length == 0 || !this.state.selectedMagicCarpet) {
            return null;
        }

        var list = [];
        for (var i = 0; i < this.state.magicCarpetFields.length; i++) {
            var field = this.state.magicCarpetFields[i];
            var item = (
                <MCFieldListItem
                    key={"" + i}
                    data={field}
                    selectedMagicCarpet={this.state.selectedMagicCarpet}
                    selectedField={this.state.selectedField}
                    onClick={this.onMCFieldListItemClick.bind(this)} />
            )
            list.push(item);
        }

        return (
            <div style={styles.column}>
                <div style={styles.columnHeaderSection}>Fields</div>
                <div style={styles.magicCarpetFieldsContainer}>
                    {list}
                </div>
            </div>
        )
    }

    onUnSavedChangeStateChange() {
        this.setState({
            hasUnsavedChange: true,
        })
    }

    onSavePathChange(newPath) {
        // formatting
        newPath = newPath.toLocaleLowerCase();
        if (newPath.charAt(0) == '/') {
            newPath = this.removeByIndex(newPath, 0);
        }

        var copy = JSON.parse(JSON.stringify(this.state.selectedMagicCarpet));
        copy.path = newPath;
        const db = app.firestore();
        console.log("saving...");
        console.log(copy);
        db.collection("magic-carpets").doc(this.state.selectedMagicCarpet._id).set(copy).then(function () {
            window.alert("Saved successfully.");
        }.bind(this))
            .catch(function (error) {
                window.alert("Saved failed.");
            });;

        // update magic carpet list
        var magicCarpetsCopy = JSON.parse(JSON.stringify(this.state.magicCarpets))
        for (var i = 0; i < magicCarpetsCopy.length; i++) {
            if (magicCarpetsCopy[i]._id === copy._id) {
                magicCarpetsCopy[i] = copy;
            }
        }

        this.setState({
            magicCarpets: magicCarpetsCopy,
            selectedMagicCarpet: copy,
        })
    }

    onContentSave(newContent) {

        var isValid = utils.validateHtml(newContent);

        if (!(isValid === true)) {
            alert("Error on saving. The HTML is malformed. Double check the html tags or validate the content in an online html validator.");
            return;
        }

        var copy = JSON.parse(JSON.stringify(this.state.selectedMagicCarpet));
        copy[this.state.selectedField.id] = newContent;
        const db = app.firestore();
        console.log("saving...");
        console.log(copy);
        db.collection("magic-carpets").doc(this.state.selectedMagicCarpet._id).set(copy).then(function () {
            window.alert("Saved successfully.");
        }.bind(this))
            .catch(function (error) {
                window.alert("Saved failed.");
            });;

        // update magic carpet list
        var magicCarpetsCopy = JSON.parse(JSON.stringify(this.state.magicCarpets))
        for (var i = 0; i < magicCarpetsCopy.length; i++) {
            if (magicCarpetsCopy[i].path === copy.path) {
                magicCarpetsCopy[i] = copy;
            }
        }

        this.setState({
            magicCarpets: magicCarpetsCopy,
            selectedMagicCarpet: copy,
            hasUnsavedChange: false,
        })
    }

    getMagicCarpetTitles() {
        if (!this.state.magicCarpets || this.state.magicCarpets.length == 0) {
            return null;
        }

        var list = [];
        for (var i = 0; i < this.state.magicCarpets.length; i++) {
            var mc = this.state.magicCarpets[i];

            if (this.state.filterBy && this.state.filterBy.length > 0 && mc.path.indexOf(this.state.filterBy) < 0) {
                continue;
            }

            var item = (
                <MCListItem
                    key={"" + i}
                    data={mc}
                    fields={this.state.magicCarpetFields}
                    selectedData={this.state.selectedMagicCarpet}
                    onClick={this.onMCListItemClick.bind(this)} />
            )
            list.push(item);
        }

        return list;
    }

    onMCFieldListItemClick(field) {

        if (this.state.hasUnsavedChange) {
            if (window.confirm('You have unsaved changes. Discard current change?')) {
                // confirm discard change. fallbackt to setState.
            } else {
                // exit flow.
                return;
            };
        }

        this.setState({
            selectedField: field,
            hasUnsavedChange: false,
        });
    }

    onMCListItemClick(mcData) {

        if (this.state.hasUnsavedChange) {
            if (window.confirm('You have unsaved changes. Discard current change?')) {
                // confirm discard change. fallbackt to setState.
            } else {
                // exit flow.
                return;
            };
        }

        this.setState({
            selectedMagicCarpet: mcData,
            selectedField: null,
            hasUnsavedChange: false,
        })
    }

    addMCButtonClicked() {
        var result = window.prompt("Magic carpet slug (Example: totem-lake)", "");

        if (!result || result.trim().length == 0) {
            return;
        }

        // formatting
        result = result.toLocaleLowerCase();
        if (result.charAt(0) == '/') {
            result = this.removeByIndex(result, 0);
        }

        // duplicate validation
        var filtered = this.state.magicCarpets.filter(function (mc) {
            return mc.path === result;
        })

        if (filtered.length > 0) {
            console.log("duplicate");
            return;
        }

        const db = app.firestore();
        db.collection("magic-carpets").add({
            path: result,
        }).then(function (docRef) {
            console.log("Document written with ID: ", docRef.id);
            this.fetchData(docRef.id);
        }.bind(this))
            .catch(function (error) {
                console.error("Error adding document: ", error);
            });
    }

    exportDataButtonClicked() {
        const exportName = "vacation_mc_content.json";
        var jsonString = JSON.stringify(this.state.magicCarpets, null, 2);

        var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(jsonString);
        var downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href", dataStr);
        downloadAnchorNode.setAttribute("download", exportName);
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }

    async fetchData(autoSelectByIdAfterFetch = null) {
        const db = app.firestore();
        const magicCarpetsData = await db.collection('magic-carpets').get();
        const magicCarpets = magicCarpetsData.docs.map(doc => ({ ...doc.data(), _id: doc.id }));

        console.log(magicCarpets);

        const magicCarpetFieldsData = await db.collection('magic-carpet-fields').get();
        var magicCarpetFields = magicCarpetFieldsData.docs.map(doc => ({ ...doc.data(), _id: doc.id }));
        magicCarpetFields.sort(function (a, b) {

            var aIndex = parseInt(a.displayName.substr(0, a.displayName.indexOf('.')));
            var bIndex = parseInt(b.displayName.substr(0, b.displayName.indexOf('.')));

            console.log(`a: ${aIndex} b: ${bIndex}`);

            return aIndex > bIndex ? 1 : -1;
        });

        // if need auto-select a certain element after fetch, pick it here.
        var selected = null;
        if (autoSelectByIdAfterFetch) {
            var filtered = magicCarpets.filter(
                function (mc) {
                    return mc._id === autoSelectByIdAfterFetch;
                }
            );
            if (filtered.length > 0) {
                selected = filtered[0];
            }
        }


        this.setState({
            magicCarpets: magicCarpets,
            magicCarpetFields: magicCarpetFields,
            hasUnsavedChange: false,
            selectedMagicCarpet: selected,
        })
    }

    // remove a char at certain index of a string
    removeByIndex(str, index) {
        if (index == 0) {
            return str.slice(1)
        } else {
            return str.slice(0, index - 1) + str.slice(index);
        }
    }
}

var styles = {
    container: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
    navbar: {
        fontWeight: 600,
        fontSize: 18,
        padding: `15px ${SharedStyles.horizontalSpacing}px`,
        borderBottom: `solid 1px ${SharedStyles.borderColor}`,
    },
    editorContainer: {
        display: 'flex',
        height: '100%',
        width: '100vw',
        flex: 1,
        flexDirection: 'row',
    },
    column: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRight: `solid 1px ${SharedStyles.borderColor}`,
    },
    columnHeaderSection: {
        borderBottom: `solid 1px ${SharedStyles.borderColor}`,
        padding: `15px ${SharedStyles.horizontalSpacing}px`,
    },
    columnFooterSection: {
        borderTop: `solid 1px ${SharedStyles.borderColor}`,
        padding: `15px ${SharedStyles.horizontalSpacing}px`,
    },
    columnHeaderSearchSection: {
        borderBottom: `solid 1px ${SharedStyles.borderColor}`,
        padding: `15px ${SharedStyles.horizontalSpacing}px`,
    },
    searchBox: {
        border: 'none',
        fontWeight: '700',
    },
    magicCarpetsContainer: {
        width: 220,
        flex: 1,
        overflow: 'auto',
    },
    magicCarpetFieldsContainer: {
        width: 220,
        flex: 1,
        whiteSpace: 'nowrap',
        overflow: 'auto',
        borderRight: `solid 1px ${SharedStyles.borderColor}`,
    },
    contentEditorContainer: {
        flex: 1,
        height: '100%',
        position: 'relative',
    },
    addMcButton: {
        color: SharedStyles.blue,
        fontWeight: 600,
        cursor: 'pointer',
    },
}

export default component;