import React from 'react';
import { withRouter } from 'react-router';

class component extends React.Component {
    render() {
        return (
            <div>
                <div>
                    <button onClick={() => this.props.history.push('/content-editor')}>Content editor</button>
                    <button onClick={() => this.props.history.push('/keyword-benchmark')}>Keyword benchmark</button>
                    <button onClick={() => this.props.history.push('/blog-cms')}>Blog CMS</button>
                    <button onClick={() => this.props.history.push('/sem-modeler')}>SEM Modeler</button>
                    <button onClick={() => this.props.history.push('/logout')}>Logout</button>
                </div>
            </div>);
    }
}

export default component;