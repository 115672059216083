import React from 'react';
import app from '../firebase';
import BlogPostListItem from '../components/blogcms/blogpostlistitem';
import BlogPostFieldListItem from '../components/blogcms/blogpostfieldslistitem';
import AttributesView from '../components/blogcms/attributesview'
import BlogPostHTML from '../components/blogcms/blogposthtmlpreview';
import SharedStyles from '../sharedstyles';
import utils from '../utils/utils';
import fetch from 'isomorphic-fetch';

class component extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            blogPosts: [],
            magicCarpetFields: [],
            selectedBlogPost: null,
            selectedField: null,
            hasUnsavedChange: false,
            filterBy: null,
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    render() {
        return (
            <div style={styles.container}>
                <div style={styles.editorContainer}>
                    <div style={styles.column}>
                        <div style={styles.columnHeaderSection}>
                            <div style={styles.margetCarpetColumnTitle}>Blog Posts ({this.state.blogPosts.length})</div>
                        </div>
                        <div style={styles.columnHeaderSearchSection}>
                            <input
                                className="mcSearchInput"
                                style={styles.searchBox}
                                placeholder="Search"
                                value={this.state.filterBy}
                                onChange={e => this.setState({ filterBy: e.target.value })} />
                        </div>
                        <div style={styles.blogPostsContainer}>
                            {this.getBlogTitles()}
                        </div>
                        <div style={styles.columnFooterSection}>
                            <div style={styles.addMcButton} onClick={this.addMCButtonClicked.bind(this)}>+ Add new post</div>
                        </div>
                        <div style={styles.columnFooterSection}>
                            <div style={styles.addMcButton} onClick={this.loadFromCMS.bind(this, true)}>Reload from CMS</div>
                        </div>
                        <div style={styles.columnFooterSection}>
                            <div style={styles.addMcButton} onClick={this.exportDataButtonClicked.bind(this)}>Export JSON</div>
                        </div>
                    </div>
                    {this.getFields()}
                    {this.getEditor()}
                </div>
            </div>
        );
    }

    getEditor() {
        if (!this.state.selectedBlogPost || !this.state.selectedField) {
            return null;
        }

        if (this.state.selectedField == "attributes-view") {
            return (
                <div style={styles.contentEditorContainer}>
                    <AttributesView
                        data={this.state.selectedBlogPost}
                        onSave={this.onAttributesSave.bind(this)}
                        blogPosts={this.state.blogPosts}
                    />
                </div>)
        }
        else if (this.state.selectedField == "post-body-view")
            return (
                <div style={styles.contentEditorContainer}>
                    <BlogPostHTML
                        data={this.state.selectedBlogPost}
                    />
                </div>)

        else {
            return (<div>Field view not defined</div>)
        }
    }

    getFields() {
        if (!this.state.selectedBlogPost) {
            return null;
        }

        return (
            <div style={styles.column}>
                <div style={styles.columnHeaderSection}>Fields</div>
                <div style={styles.fieldsContainer}>
                    <BlogPostFieldListItem
                        displayName="Attributes"
                        id="attributes-view"
                        selectedBlogPost={this.state.selectedBlogPost}
                        selectedField={this.state.selectedField}
                        onClick={this.onBlogPostFieldListItemClick.bind(this)} />
                    <BlogPostFieldListItem
                        displayName="Post Body"
                        id="post-body-view"
                        selectedBlogPost={this.state.selectedBlogPost}
                        selectedField={this.state.selectedField}
                        onClick={this.onBlogPostFieldListItemClick.bind(this)} />
                </div>
            </div>
        )
    }

    onUnSavedChangeStateChange() {
        this.setState({
            hasUnsavedChange: true,
        })
    }

    onSavePathChange(newPath) {
        // formatting
        newPath = newPath.toLocaleLowerCase();
        if (newPath.charAt(0) == '/') {
            newPath = this.removeByIndex(newPath, 0);
        }

        var copy = JSON.parse(JSON.stringify(this.state.selectedBlogPost));
        copy.path = newPath;
        const db = app.firestore();
        console.log("saving...");
        console.log(copy);
        db.collection("magic-carpets").doc(this.state.selectedBlogPost._id).set(copy).then(function () {
            window.alert("Saved successfully.");
        }.bind(this))
            .catch(function (error) {
                window.alert("Saved failed.");
            });;

        // update magic carpet list
        var blogPostsCopy = JSON.parse(JSON.stringify(this.state.blogPosts))
        for (var i = 0; i < blogPostsCopy.length; i++) {
            if (blogPostsCopy[i]._id === copy._id) {
                blogPostsCopy[i] = copy;
            }
        }

        this.setState({
            blogPosts: blogPostsCopy,
            selectedBlogPost: copy,
        })
    }

    onAttributesSave(data) {
        var copy = JSON.parse(JSON.stringify(data));

        const db = app.firestore();
        db.collection("blog-posts").doc(this.state.selectedBlogPost._id).set(copy).then(function () {
            window.alert("Saved successfully.");
        }.bind(this))
            .catch(function (error) {
                window.alert("Saved failed.");
            });;

        // update blogs list
        var blogPostsCopy = JSON.parse(JSON.stringify(this.state.blogPosts))
        for (var i = 0; i < blogPostsCopy.length; i++) {
            if (blogPostsCopy[i]._id === copy._id) {
                blogPostsCopy[i] = copy;
            }
        }

        this.setState({
            blogPosts: blogPostsCopy,
            selectedBlogPost: copy,
            hasUnsavedChange: false,
        })
    }

    onContentSave(newContent) {

        var isValid = utils.validateHtml(newContent);

        if (!(isValid === true)) {
            alert("Error on saving. The HTML is malformed. Double check the html tags or validate the content in an online html validator.");
            return;
        }

        var copy = JSON.parse(JSON.stringify(this.state.selectedBlogPost));
        copy["body"] = newContent;
        const db = app.firestore();
        console.log("saving...");
        console.log(copy);
        db.collection("blog-posts").doc(this.state.selectedBlogPost._id).set(copy).then(function () {
            window.alert("Saved successfully.");
        }.bind(this))
            .catch(function (error) {
                window.alert("Saved failed.");
            });;

        // update magic carpet list
        var blogPostsCopy = JSON.parse(JSON.stringify(this.state.blogPosts))
        for (var i = 0; i < blogPostsCopy.length; i++) {
            if (blogPostsCopy[i]._id === copy._id) {
                blogPostsCopy[i] = copy;
            }
        }

        this.setState({
            blogPosts: blogPostsCopy,
            selectedBlogPost: copy,
            hasUnsavedChange: false,
        })
    }

    getBlogTitles() {
        if (!this.state.blogPosts || this.state.blogPosts.length == 0) {
            return null;
        }

        var list = [];
        for (var i = 0; i < this.state.blogPosts.length; i++) {
            var blogPost = this.state.blogPosts[i];

            if (this.state.filterBy && this.state.filterBy.length > 0 && blogPost.slug.indexOf(this.state.filterBy) < 0) {
                continue;
            }

            var item = (
                <BlogPostListItem
                    key={"" + i}
                    data={blogPost}
                    fields={this.state.magicCarpetFields}
                    selectedData={this.state.selectedBlogPost}
                    onClick={this.onMCListItemClick.bind(this)} />
            )
            list.push(item);
        }

        return list;
    }

    onBlogPostFieldListItemClick(field) {

        if (this.state.hasUnsavedChange) {
            if (window.confirm('You have unsaved changes. Discard current change?')) {
                // confirm discard change. fallbackt to setState.
            } else {
                // exit flow.
                return;
            };
        }

        this.setState({
            selectedField: field,
            hasUnsavedChange: false,
        });
    }

    onMCListItemClick(post) {

        if (this.state.hasUnsavedChange) {
            if (window.confirm('You have unsaved changes. Discard current change?')) {
                // confirm discard change. fallbackt to setState.
            } else {
                // exit flow.
                return;
            };
        }

        this.setState({
            selectedBlogPost: post,
            selectedField: null,
            hasUnsavedChange: false,
        })
    }

    addMCButtonClicked() {
        var result = window.prompt("Blog post slug (Example: great-smoky-mountains-cabins)", "");

        if (!result || result.trim().length == 0) {
            return;
        }

        // formatting
        result = result.toLocaleLowerCase();
        if (result.charAt(0) == '/') {
            result = this.removeByIndex(result, 0);
        }

        // duplicate validation
        var filtered = this.state.blogPosts.filter(function (mc) {
            return mc.slug === result;
        })

        if (filtered.length > 0) {
            console.log("duplicate");
            return;
        }

        const db = app.firestore();
        db.collection("blog-posts").add({
            slug: result,
        }).then(function (docRef) {
            console.log("Document written with ID: ", docRef.id);
            this.fetchData(docRef.id);
        }.bind(this))
            .catch(function (error) {
                console.error("Error adding document: ", error);
            });
    }

    exportDataButtonClicked() {
        const exportName = "blog_content.json";
        var jsonString = JSON.stringify(this.state.blogPosts, null, 2);

        var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(jsonString);
        var downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href", dataStr);
        downloadAnchorNode.setAttribute("download", exportName);
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }

    async loadFromCMS(completionMessage = false) {

        var cmsData = [];

        // Wordpress api is paged at most 100 posts per page so we query all pages.
        var hasNextPage = true;
        var currentPageNumber = 1;
        while (hasNextPage) {
            const pageData = await (await fetch('https://cms.pageonelab.com/wp-json/wp/v2/posts?per_page=100&page=' + currentPageNumber)).json();

            if (pageData.code) {
                hasNextPage = false;
                console.log(`Queried ${currentPageNumber} pages from CMS.`)
            }
            else {
                cmsData = cmsData.concat(pageData);
                currentPageNumber += 1;
            }
        }


        var needReloadData = false
        console.log(cmsData);

        for (var i = 0; i < cmsData.length; i++) {
            var idStr = cmsData[i].id.toString();

            for (var j = 0; j < this.state.blogPosts.length; j++) {
                var post = JSON.parse(JSON.stringify(this.state.blogPosts[j]));

                if (post["cms-id"] === idStr && post.body !== cmsData[i].content.rendered) {
                    console.log(`Updating cms-id: ${idStr} slug: ${post.slug}`);
                    post.body = cmsData[i].content.rendered
                    post.excerpt = cmsData[i].excerpt.rendered
                    await this.savePostAsync(post);

                    needReloadData = true
                }
            }
        }

        if (needReloadData) {
            this.fetchData(null, true)
        }

        if (completionMessage) {
            alert("CMS Data reloaded.")
        }
    }

    async savePostAsync(post) {
        const db = app.firestore();
        console.log("saving...");
        console.log(post);
        await db.collection("blog-posts").doc(post._id).set(post);
    }

    async fetchData(autoSelectByIdAfterFetch = null, skipFetchCMS = false) {
        const db = app.firestore();
        const blogPostsData = await db.collection('blog-posts').get();
        const blogPosts = blogPostsData.docs.map(doc => ({ ...doc.data(), _id: doc.id }));

        console.log(blogPosts);

        // if need auto-select a certain element after fetch, pick it here.
        var selected = null;
        if (autoSelectByIdAfterFetch) {
            var filtered = blogPosts.filter(
                function (blogPost) {
                    return blogPost._id === autoSelectByIdAfterFetch;
                }
            );
            if (filtered.length > 0) {
                selected = filtered[0];
            }
        }


        this.setState({
            blogPosts: blogPosts,
            hasUnsavedChange: false,
            selectedBlogPost: selected,
        }, function () {
            if (!skipFetchCMS) {
                this.loadFromCMS()
            }
        }.bind(this))
    }

    // remove a char at certain index of a string
    removeByIndex(str, index) {
        if (index == 0) {
            return str.slice(1)
        } else {
            return str.slice(0, index - 1) + str.slice(index);
        }
    }
}

var styles = {
    container: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
    navbar: {
        fontWeight: 600,
        fontSize: 18,
        padding: `15px ${SharedStyles.horizontalSpacing}px`,
        borderBottom: `solid 1px ${SharedStyles.borderColor}`,
    },
    editorContainer: {
        display: 'flex',
        height: '100%',
        width: '100vw',
        flex: 1,
        flexDirection: 'row',
    },
    column: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRight: `solid 1px ${SharedStyles.borderColor}`,
    },
    columnHeaderSection: {
        borderBottom: `solid 1px ${SharedStyles.borderColor}`,
        padding: `15px ${SharedStyles.horizontalSpacing}px`,
    },
    columnFooterSection: {
        borderTop: `solid 1px ${SharedStyles.borderColor}`,
        padding: `15px ${SharedStyles.horizontalSpacing}px`,
    },
    columnHeaderSearchSection: {
        borderBottom: `solid 1px ${SharedStyles.borderColor}`,
        padding: `15px ${SharedStyles.horizontalSpacing}px`,
    },
    searchBox: {
        border: 'none',
        fontWeight: '700',
    },
    blogPostsContainer: {
        width: 220,
        flex: 1,
        overflow: 'auto',
    },
    fieldsContainer: {
        width: 220,
        flex: 1,
        whiteSpace: 'nowrap',
        overflow: 'auto',
        borderRight: `solid 1px ${SharedStyles.borderColor}`,
    },
    contentEditorContainer: {
        flex: 1,
        height: '100%',
        position: 'relative',
    },
    addMcButton: {
        color: SharedStyles.blue,
        fontWeight: 600,
        cursor: 'pointer',
    },
}

export default component;