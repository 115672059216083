import React from 'react';
import { Form, Input, Button, Divider, Select, Image } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import SharedStyles from '../../sharedstyles';
import './blog.css'

// const [form] = Form.useForm();

class component extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.data !== state.data) {
            return {
                data: props.data
            };
        }
        return null;
    }

    render() {
        return (
            <div style={styles.container}>
                <Divider>Content</Divider>
                <div
                    style={styles.previewSection}
                    className="blog-post-container"
                    dangerouslySetInnerHTML={{ __html: this.state.data.body }}></div>
                <Divider>Excerpt</Divider>
                <div
                    style={styles.previewSection}
                    className="blog-post-container"
                    dangerouslySetInnerHTML={{ __html: this.state.data.excerpt }}></div>
            </div>
        )
    }
}

var styles = {
    container: {
        flex: 1,
        height: '100%',
        padding: `10px ${SharedStyles.horizontalSpacing}px`,
        overflowY: 'scroll',
    },
    previewSection: {
        maxWidth: '100%',
        wordWrap: "break-word",
        wordBreak: "break-word",
    }
}

export default component;