export default {
    // strip html tag and count words.
    countHtmlWords: function(str){
        if(!str || str.length == 0){
            return 0;
        }

        var copy = JSON.parse(JSON.stringify(str)).replace(/(<([^>]+)>)/ig, "");
        var words = copy.trim();

        if (words.length == 0){
          return 0;
        }

        words = words.split(" ");
        
        return words.length;
    },
    validateHtml: function(htmlStr, strictBoolean=false) {
        if (typeof htmlStr !== "string")
          return false;
      
        var validateHtmlTag = new RegExp("<[a-z]+(\s+|\"[^\"]*\"\s?|'[^']*'\s?|[^'\">])*>", "igm"),
          sdom = document.createElement('div'),
          noSrcNoAmpHtmlStr = htmlStr
            .replace(/ src=/, " svhs___src=") // disarm src attributes
            .replace(/&amp;/igm, "#svhs#amp##"), // 'save' encoded ampersands
          noSrcNoAmpIgnoreScriptContentHtmlStr = noSrcNoAmpHtmlStr
            .replace(/\n\r?/igm, "#svhs#nl##") // temporarily remove line breaks
            .replace(/(<script[^>]*>)(.*?)(<\/script>)/igm, "$1$3") // ignore script contents
            .replace(/#svhs#nl##/igm, "\n\r"),  // re-add line breaks
          htmlTags = noSrcNoAmpIgnoreScriptContentHtmlStr.match(/<[a-z]+[^>]*>/igm), // get all start-tags
          htmlTagsCount = htmlTags ? htmlTags.length : 0,
          tagsAreValid, resHtmlStr;
      
      
        if(!strictBoolean){
          // ignore <br/> conversions
          noSrcNoAmpHtmlStr = noSrcNoAmpHtmlStr.replace(/<br\s*\/>/, "<br>")
        }
      
        if (htmlTagsCount) {
          tagsAreValid = htmlTags.reduce(function(isValid, tagStr) {
            return isValid && tagStr.match(validateHtmlTag);
          }, true);
      
          if (!tagsAreValid) {
            return false;
          }
        }
      
      
        try {
          sdom.innerHTML = noSrcNoAmpHtmlStr;
        } catch (err) {
          return false;
        }
      
        // compare rendered tag-count with expected tag-count
        if (sdom.querySelectorAll("*").length !== htmlTagsCount) {
          return false;
        }
      
        resHtmlStr = sdom.innerHTML.replace(/&amp;/igm, "&"); // undo '&' encoding
      
        if(!strictBoolean){
          // ignore empty attribute normalizations
          resHtmlStr = resHtmlStr.replace(/=""/, "")
        }
      
        // compare html strings while ignoring case, quote-changes, trailing spaces
        var
          simpleIn = noSrcNoAmpHtmlStr.replace(/["']/igm, "").replace(/\s+/igm, " ").toLowerCase().trim(),
          simpleOut = resHtmlStr.replace(/["']/igm, "").replace(/\s+/igm, " ").toLowerCase().trim();
        if (simpleIn === simpleOut)
          return true;
      
        return resHtmlStr.replace(/ svhs___src=/igm, " src=").replace(/#svhs#amp##/, "&amp;");
      }
}